var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "mb aliceblue",
      attrs: { align: "middle", id: "events", justify: "center", type: "flex" }
    },
    _vm._l(_vm.events, function(event) {
      return _c(
        "a-col",
        {
          key: event.id,
          staticStyle: { "margin-bottom": "20px" },
          attrs: { md: 6 }
        },
        [
          _c(
            "a-card",
            { staticStyle: { width: "300px" }, attrs: { hoverable: "" } },
            [
              _c("img", {
                staticClass: "cover_img",
                attrs: {
                  slot: "cover",
                  alt: event.titre,
                  src: event.logo_url[0]
                },
                on: {
                  click: function($event) {
                    return _vm.openModal(event)
                  }
                },
                slot: "cover"
              }),
              _c(
                "a-modal",
                {
                  attrs: {
                    visible: _vm.modalOpen,
                    "dialog-style": { position: "fixed", inset: "50px" },
                    "body-style": { paddingBottom: "50px" },
                    footer: null,
                    closable: true,
                    maskClosable: true
                  }
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "closeIcon" },
                      on: { click: _vm.closeModal },
                      slot: "closeIcon"
                    },
                    [_vm._v("\n                    x\n                ")]
                  ),
                  _c(
                    "a-carousel",
                    {
                      attrs: {
                        arrows: "",
                        "dots-class": "slick-dots slick-thumb"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prevArrow",
                            fn: function(props) {
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-slick-arrow",
                                  staticStyle: { left: "1px", zIndex: "1" }
                                },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "left-circle" }
                                  })
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "nextArrow",
                            fn: function(props) {
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-slick-arrow",
                                  staticStyle: { right: "1px" }
                                },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "right-circle" }
                                  })
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "customPaging",
                            fn: function(props) {
                              return _c("a", {}, [
                                _vm.isImage(_vm.carousel_images[props.i])
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.carousel_images[props.i]
                                      }
                                    })
                                  : _c("video", [
                                      _c("source", {
                                        attrs: {
                                          src: _vm.carousel_images[props.i]
                                        }
                                      })
                                    ])
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    _vm._l(_vm.carousel_images, function(image, idx) {
                      return _c(
                        "div",
                        { key: idx },
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { height: "70vh" },
                              attrs: {
                                type: "flex",
                                justify: "center",
                                aling: "middle"
                              }
                            },
                            [
                              _vm.isImage(image)
                                ? _c("img", { attrs: { src: image } })
                                : _c("video", { attrs: { controls: "" } }, [
                                    _c("source", { attrs: { src: image } })
                                  ])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("a-card-meta", [
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(event.titre) +
                      "\n                "
                  )
                ]),
                _c(
                  "span",
                  { attrs: { slot: "description" }, slot: "description" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(event.dt_evenement) +
                        " "
                    ),
                    _c("br"),
                    _c(
                      "a-collapse",
                      {
                        attrs: {
                          bordered: false,
                          "expand-icon-position": "right"
                        }
                      },
                      [
                        _c(
                          "a-collapse-panel",
                          {
                            key: "1",
                            staticStyle: {
                              background: "#fff",
                              "border-radius": "4px",
                              border: "0",
                              overflow: "hidden"
                            },
                            attrs: {
                              "show-arrow": true,
                              header: "Voir plus..."
                            }
                          },
                          [_c("p", [_vm._v(_vm._s(event.contenu))])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }