<template>
    <a-row align="middle" id="events" class="mb aliceblue" justify="center" type="flex">
        <a-col v-for="event in events" :key="event.id" :md="6" style=" margin-bottom : 20px;">
            <a-card hoverable style="width: 300px">
                <img @click="openModal(event)" slot="cover" :alt="event.titre" :src="event.logo_url[0]"
                    class="cover_img" />
                <a-modal :visible="modalOpen" :dialog-style="{ position: 'fixed', inset: '50px' }"
                    :body-style="{ paddingBottom: '50px' }" :footer="null" :closable="true" :maskClosable="true">
                    <div @click="closeModal" slot="closeIcon">
                        x
                    </div>
                    <a-carousel arrows dots-class="slick-dots slick-thumb">
                        <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 1px;zIndex: 1">
                            <a-icon type="left-circle" />
                        </div>
                        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 1px">
                            <a-icon type="right-circle" />
                        </div>
                        <a slot="customPaging" slot-scope="props">
                            <img v-if="isImage(carousel_images[props.i])" :src="carousel_images[props.i]" />
                            <video v-else>
                                <source :src="carousel_images[props.i]">
                            </video>
                        </a>
                        <div v-for="(image, idx) in carousel_images" :key="idx">
                            <a-row type="flex" justify="center" aling="middle" style="height: 70vh;">
                                <img v-if="isImage(image)" :src="image" />
                                <video v-else controls>
                                    <source :src="image">
                                </video>
                            </a-row>
                        </div>
                    </a-carousel>
                </a-modal>

                <a-card-meta>
                    <span slot="title">
                        {{ event.titre }}
                    </span>
                    <span slot="description">
                        {{ event.dt_evenement }} <br>
                        <a-collapse :bordered="false" expand-icon-position="right">
                            <a-collapse-panel key="1" :show-arrow="true" header="Voir plus..."
                                style="background: #fff;border-radius: 4px;border: 0;overflow: hidden">
                                <p>{{ event.contenu }}</p>
                            </a-collapse-panel>
                        </a-collapse>
                    </span>
                </a-card-meta>
            </a-card>
        </a-col>
    </a-row>
</template>
<script>
export default {
    name: "Events",
    data() {
        return {
            modalOpen: false,
            carousel_images: [],
        }
    },
    props: { events: { type: Array } },
    methods: {
        openModal: function (event) {
            this.modalOpen = true;
            this.carousel_images = event.logo_url;
        },
        closeModal: function () {
            this.modalOpen = false;
            this.carousel_images = [];
        },
        isImage: function (filename) {
            const extension = filename.split('.').pop();
            const image_extensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
            return image_extensions.includes(extension);
        },
    },
}
</script>
<style scoped>
.cover_img {
    height: 167px;
}

.ant-collapse {
    background-color: white;
}

.ant-collapse-header {
    font-size: 16px;
}


/* Source : https://1x.antdv.com/components/carousel/#Carousel */
.ant-carousel>>>.slick-dots {
    height: auto;
}

.ant-carousel>>>.slick-slide {
    max-height: 75vh;
}

.ant-carousel>>>.slick-slide img,
.ant-carousel>>>.slick-slide video {
    border: 1px solid rgba(0, 0, 0, 0.63);
    display: block;
    margin: auto;
    max-width: 90%;
    max-height: 100%;
}

.ant-carousel>>>.slick-thumb {
    bottom: -45px;
}

.ant-carousel>>>.slick-thumb li {
    width: 60px;
    height: 45px;
}

.ant-carousel>>>.slick-thumb li img,
.ant-carousel>>>.slick-thumb li video {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

.ant-carousel>>>.slick-thumb li.slick-active img,
.ant-carousel>>>.slick-thumb li.slick-active video {
    filter: grayscale(0%);
}

.ant-carousel>>>.custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #000;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
}

.ant-carousel>>>.custom-slick-arrow:before {
    display: none;
}

.ant-carousel>>>.custom-slick-arrow:hover {
    opacity: 0.5;
}
</style>